import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const MegaFlushedPage = () => {
  return (
    <SingleImagePage
      imageUrl="mega-flushed.png"
      title="Free Animated Mega Flushed Emoji Maker"
      description="Create custom animated Mega Flushed emojis and emotes for Twitch, Discord, and Slack. Turn the flushed emoji face into animated emotes with shake, spin, party effects and more!"
      alt="A flushed emoji face"
    />
  );
};

export default MegaFlushedPage;
