import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const MonkasPage = () => {
  return (
    <SingleImagePage
      imageUrl="monkas.png"
      title="Free Animated MonkaS Emoji Maker"
      description="Create custom animated MonkaS emojis and emotes for Twitch, Discord, and Slack. Turn the popular MonkaS meme into animated emotes with shake, spin, party effects and more!"
      alt="The MonkasS meme"
    />
  );
};

export default MonkasPage;
