import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const PJSaltPage = () => {
  return (
    <SingleImagePage
      imageUrl="pjsalt.png"
      title="Free Animated PJSalt Emoji Maker"
      description="Create custom animated PJSalt emojis and emotes for Twitch, Discord, and Slack. Turn the PJSalt meme into animated emotes with shake, spin, party effects and more!"
      alt="The PJSalt meme"
    />
  );
};

export default PJSaltPage;
