import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const DwaynePage = () => {
  return (
    <SingleImagePage
      imageUrl="dwayne.png"
      title="Free Animated The Rock Eyebrow Emoji Maker"
      description="Create custom animated The Rock (Dwayne Johnson) raising eyebrow emojis and emotes for Twitch, Discord, and Slack. Turn The Rock's iconic expression into animated emotes with shake, spin, party effects and more!"
      alt="The Rock raising an eyebrow"
    />
  );
};

export default DwaynePage;
