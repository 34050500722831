import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const DoritosPage = () => {
  return (
    <SingleImagePage
      imageUrl="doritos.png"
      title="Free Animated Dorito Emoji Maker"
      description="Create custom animated Dorito emojis and emotes for Twitch, Discord, and Slack. Turn the Dorito meme into animated emotes with shake, spin, party effects and more!"
      alt="A Dorito"
    />
  );
};

export default DoritosPage;
