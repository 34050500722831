import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const DuckPage = () => {
  return (
    <SingleImagePage
      imageUrl="duck.png"
      title="Free Animated Duck with Knife Emoji Maker"
      description="Create custom animated Duck with Knife emojis and emotes for Twitch, Discord, and Slack. Turn the stuffed duck with knife meme into animated emotes with shake, spin, party effects and more!"
      alt="A stuffed duck with a knife"
    />
  );
};

export default DuckPage;
