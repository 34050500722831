import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const RipPage = () => {
  return (
    <SingleImagePage
      imageUrl="rip.png"
      title="Free Animated RIP Emoji Maker"
      description="Create custom animated RIP emojis and emotes for Twitch, Discord, and Slack. Turn the gravestone R.I.P. meme into animated emotes with shake, spin, party effects and more!"
      alt="A gravestone with R.I.P. on it"
    />
  );
};

export default RipPage;
