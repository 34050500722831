export const emojiSpeeds = {
  ["pet"]: { slow: 0.06, normal: 0.045, fast: 0.02 },
  ["jam"]: { slow: 0.09, normal: 0.06, fast: 0.03 },
  ["jammies"]: { slow: 0.09, normal: 0.06, fast: 0.03 }
  // ... copy the rest of emojiSpeeds from App/index.tsx
};

export const emojiStyles = [
  ["pet", "pet", 0.1],
  ["party-parrot", "partyParrot", 0.035],
  ["party-blob", "partyBlob", 0.035]
  // ... copy the rest of emojiStyles from App/index.tsx
];
