import React, { FunctionComponent, Suspense, useEffect, useState } from "react";
import { EditArea, EmojiArea, Header } from "../App/styled";
import EmojiPanel from "../EmojiPanel";
import useResizedImage from "../App/useResizedImage";
import { emojiStyles, emojiSpeeds } from "../../constants/emojiConfig";

interface SingleImagePageProps {
  imageUrl: string;
  title: string;
  description: string;
  alt: string;
}

export const SingleImagePage: FunctionComponent<SingleImagePageProps> = ({
  imageUrl,
  title,
  description,
  alt
}) => {
  const [base, setBase] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const resized = useResizedImage(base);

  useEffect(() => {
    (async () => {
      const fetched = await fetch(`/src/assets/${imageUrl}`);
      const blob = await fetched.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        if (typeof result === "string") setBase(result);
      };
      reader.readAsDataURL(blob);
    })();
  }, [imageUrl]);

  useEffect(() => {
    // @ts-ignore
    window.__makeEmojiCurrentSrc = resized;
  }, [resized]);

  const incrementCurrentIndex = () => {
    setCurrentIndex(currentIndex + 1);
  };

  return (
    <main>
      <nav>
        <a className="nav-link" href="/">
          Home
        </a>
        <a className="nav-link" href="/blog">
          Blog
        </a>
        <a className="nav-link" href="https://x.com/makeemoji">
          Follow on X / Twitter
        </a>
      </nav>

      <EditArea>
        <Header>
          <img
            className="m-logo"
            src="/src/assets/m-logo.png"
            alt="MakeEmoji"
          />
        </Header>

        <h1>{title}</h1>
        <p>{description}</p>

        {base && (
          <EmojiArea>
            <Suspense fallback={<div />}>
              {emojiStyles.map(([name, transformation]: [string, any], i) => {
                const interval = emojiSpeeds[name]["normal"];
                if (typeof transformation != "string") {
                  transformation = transformation["right"];
                }
                return (
                  <EmojiPanel
                    key={name}
                    index={i}
                    incrementCurrentIndex={incrementCurrentIndex}
                    currentIndex={currentIndex}
                    img={resized}
                    name={name as string}
                    filterColor="normal"
                    filterBrightness={50}
                    filterContrast={50}
                    filterSaturation={50}
                    filterDirection="right"
                    transformation={transformation as any}
                    interval={interval as number}
                  />
                );
              })}
            </Suspense>
          </EmojiArea>
        )}
      </EditArea>
    </main>
  );
};

export default SingleImagePage;
