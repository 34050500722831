import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const CreeperPage = () => {
  return (
    <SingleImagePage
      imageUrl="ssssss.png"
      title="Free Animated Creeper Emoji Maker"
      description="Create custom animated Minecraft Creeper emojis and emotes for Twitch, Discord, and Slack. Turn the classic Creeper face into animated emotes with shake, spin, party effects and more!"
      alt="A creeper from Minecraft"
    />
  );
};

export default CreeperPage;
