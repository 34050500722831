import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const PoggersPage = () => {
  return (
    <SingleImagePage
      imageUrl="poggers.png"
      title="Free Animated Poggers Emoji Maker"
      description="Create custom animated Poggers emojis and emotes for Twitch, Discord, and Slack. Choose from multiple animation styles including shake, spin, party, and more!"
      alt="The poggers meme"
    />
  );
};

export default PoggersPage;
