import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const KekwPage = () => {
  return (
    <SingleImagePage
      imageUrl="kekw.png"
      title="Free Animated KEKW Emoji Maker"
      description="Create custom animated KEKW emojis and emotes for Twitch, Discord, and Slack. Turn the popular KEKW laughing meme into animated emotes with shake, spin, party effects and more!"
      alt="The kekw meme"
    />
  );
};

export default KekwPage;
