import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const FPage = () => {
  return (
    <SingleImagePage
      imageUrl="f.png"
      title="Free Animated F Key Emoji Maker"
      description="Create custom animated F Key emojis and emotes for Twitch, Discord, and Slack. Turn the Press F to Pay Respects meme into animated emotes with shake, spin, party effects and more!"
      alt="The F key"
    />
  );
};

export default FPage;
