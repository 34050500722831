import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const PeepoPage = () => {
  return (
    <SingleImagePage
      imageUrl="peepo.png"
      title="Free Animated Peepo Emoji Maker"
      description="Create custom animated Peepo emojis and emotes for Twitch, Discord, and Slack. Turn the popular Peepo meme into animated emotes with bounce, spin, party effects and more!"
      alt="The Peepo meme"
    />
  );
};

export default PeepoPage;
