import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const MildPanicPage = () => {
  return (
    <SingleImagePage
      imageUrl="mild_panic.png"
      title="Free Animated Panic Emoji Maker"
      description="Create custom animated Panic Face emojis and emotes for Twitch, Discord, and Slack. Turn the sweating panic emoji into animated emotes with shake, spin, party effects and more!"
      alt="An emoji face with wide eyes and a bead of sweat"
    />
  );
};

export default MildPanicPage;
