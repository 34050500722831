import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const KittyBlushPage = () => {
  return (
    <SingleImagePage
      imageUrl="kitty-blush.png"
      title="Free Animated Kitty Blush Emoji Maker"
      description="Create custom animated Kitty Blush emojis and emotes for Twitch, Discord, and Slack. Turn the cute kitty blush meme into animated emotes with shake, spin, party effects and more!"
      alt="The kitty blush meme"
    />
  );
};

export default KittyBlushPage;
