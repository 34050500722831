import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const StonksPage = () => {
  return (
    <SingleImagePage
      imageUrl="stonks.png"
      title="Free Animated Stonks Emoji Maker"
      description="Create custom animated Stonks meme emojis and emotes for Twitch, Discord, and Slack. Turn the popular Stonks meme into animated emotes with shake, spin, party effects and more!"
      alt="The stonks meme"
    />
  );
};

export default StonksPage;
