import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const IlluminatiPage = () => {
  return (
    <SingleImagePage
      imageUrl="the-illuminati.png"
      title="Free Animated Illuminati Emoji Maker"
      description="Create custom animated Illuminati emojis and emotes for Twitch, Discord, and Slack. Turn the Illuminati symbol into animated emotes with shake, spin, party effects and more!"
      alt="The Illuminati symbol"
    />
  );
};

export default IlluminatiPage;
