import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const GigaChadPage = () => {
  return (
    <SingleImagePage
      imageUrl="gigachad.png"
      title="Free Animated GigaChad Emoji Maker"
      description="Create custom animated GigaChad emojis and emotes for Twitch, Discord, and Slack. Turn the iconic GigaChad meme into animated emotes with shake, spin, party effects and more!"
      alt="The gigachad meme"
    />
  );
};

export default GigaChadPage;
