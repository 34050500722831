import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const LaughCryPage = () => {
  return (
    <SingleImagePage
      imageUrl="laugh-cry.png"
      title="Free Animated Laugh Cry Emoji Maker"
      description="Create custom animated Laugh Cry emojis and emotes for Twitch, Discord, and Slack. Turn the half laughing half crying emoji into animated emotes with shake, spin, party effects and more!"
      alt="An emoji face with one half laughing and the other half crying"
    />
  );
};

export default LaughCryPage;
