import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const BruhPage = () => {
  return (
    <SingleImagePage
      imageUrl="bruh.png"
      title="Free Animated Bruh Emoji Maker"
      description="Create custom animated Bruh emojis and emotes for Twitch, Discord, and Slack. Turn the classic bruh meme into animated emotes with shake, spin, party effects and more!"
      alt="The bruh meme"
    />
  );
};

export default BruhPage;
