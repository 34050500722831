import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const DogePage = () => {
  return (
    <SingleImagePage
      imageUrl="doge.png"
      title="Free Animated Doge Emoji Maker"
      description="Create custom animated Doge meme emojis and emotes for Twitch, Discord, and Slack. Make your favorite Shiba Inu meme into animated emotes with shake, spin, party effects and more!"
      alt="The Shiba doge meme"
    />
  );
};

export default DogePage;
