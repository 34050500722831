import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const BibleThumpPage = () => {
  return (
    <SingleImagePage
      imageUrl="biblethump.png"
      title="Free Animated BibleThump Emoji Maker"
      description="Create custom animated BibleThump emojis and emotes for Twitch, Discord, and Slack. Turn the BibleThump meme into animated emotes with shake, spin, party effects and more!"
      alt="The BibleThump meme"
    />
  );
};

export default BibleThumpPage;
