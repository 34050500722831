import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const PoggingPage = () => {
  return (
    <SingleImagePage
      imageUrl="pogging.png"
      title="Free Animated Pogging Emoji Maker"
      description="Create custom animated Pogging emojis and emotes for Twitch, Discord, and Slack. Turn the pogging meme into animated emotes with shake, spin, party effects and more!"
      alt="The pogging meme"
    />
  );
};

export default PoggingPage;
