import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const ThinkingFacePage = () => {
  return (
    <SingleImagePage
      imageUrl="thinking-face.png"
      title="Free Animated Thonk Emoji Maker"
      description="Create custom animated Thinking Face (Thonk) emojis and emotes for Twitch, Discord, and Slack. Turn the thonking meme into animated emotes with shake, spin, party effects and more!"
      alt="The thonk meme"
    />
  );
};

export default ThinkingFacePage;
